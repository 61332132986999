import './summaryStyle.css';

export const Summary = () => {
    return (
            <div className="inner">               
                <div className="logo">
                    <h1>からあげ専門店</h1>
                    <div className="logo-title">
                        <p className="logo-main">幸の鶏</p>
                        <p className="logo-sub">~コウノトリ~</p>
                    </div>
                    <img src="/images/front202410.jpg" alt="front202410.jpg" />
                </div>
                <div className="detail">
                    <div className="attension">
                        <p>国産鶏を使用</p>
                        <p>電話注文承ります</p>
                    </div>
                    <div className="explain">
                        <p className="tel">電話: <a href="tel:0995-47-5013">0995-47-5013</a></p>
                        <p className="address"> 住所：鹿児島県霧島市国分中央1-7-30</p>
                        <p> 国分中央病院前　ブックオフ隣 </p>
                        <p className="parking">店舗横 駐車場2台あり</p>
                        <p className="time">営業時間: 11:00 〜 14:00   16:00 〜 19:30 <br />電話のラストオーダーは19:00まで</p>
                        <p className="day">定休日:毎週火曜日、第2・3水曜日</p>
                    </div>
                </div>
            </div>
    );
}