import './App.css';
import { Footer } from './componets/Footer/Footer';
import { Summary } from './componets/Summery/Summary';
import { Menu } from './componets/Menu/Menu';
import { Header } from './componets/Header/Header';

function App() {
  return (
    <>
    <Header />
    <Summary />
    <Menu />
    <Footer />
    </>
  );
}

export default App;
