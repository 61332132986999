import './headerStyle.css';

export const Header = () => {

    const handleButtonClick = () => {
        window.location.href = "tel:0995-47-5013";
    };

    return (
        <div className="header">
            <div className='center'>
                <button onClick={handleButtonClick}><p>電話で注文する</p></button>
                <p className="time">営業時間: 11:00 〜 14:00 16:00 〜 19:30<br/>電話のラストオーダーは19:00まで</p>
            </div>
        </div>
    );
}