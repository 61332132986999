import './footerStyle.css';

export const Footer = () => {
    return (
        <div className="footer">
            <div className="width">
                <p className="logo">幸の鶏</p>    
                <div className="tenpo_detail">
                    <p className="tel">電話: <a href="tel:0995-47-5013"><strong>0995-47-5013</strong></a></p>
                    <p className="time">営業時間:  11:00 〜 14:00　16:00 〜 19:30</p>
                    <p className="lastOrder">電話のラストオーダーは19:00まで</p>
                    <p>定休日:毎週火曜日、第2・3水曜日</p>
                </div>
                <div className="copyright">
                    <p>© 2021 tsuchiya39</p>
                </div>
            </div>
        </div>
        );
    }